function UserManager() {
    this.init();
};

UserManager.prototype = {
    constructor: UserManager,
    
    init: function() {
        var that = this;
    
        $("body").off("click", "#user_create_new");
        $("body").off("click", ".user_delete");
        $("body").off("click", ".user_edit");
        $("body").off("click", ".user_change_pw");
        
        $("body").on("click", "#user_create_new", function() {
            that.showCreateDialog();
        });
        
        $("body").on("click", ".user_delete", function() {
            that.showDeleteDialog($(this).data("user"));
        });              
        
        $("body").on("click", ".user_edit", function() {
            that.showEditDialog(
                $(this).data("user"),
                $(this).data("firstname"),
                $(this).data("lastname"),
                $(this).data("mail"),
                $(this).data("usertype")
            );
        });
        
        $("body").on("click", ".user_change_pw", function() {
            that.showPasswordDialog($(this).data("user"));
        });
    },
    
    createUser: function(username, firstname, lastname, mail, userType, password) {
        var that = this;
        $.ajax({
            url: "api/users/create",
            data: {
                username: username,
                firstname: firstname,
                lastname: lastname,
                mail: mail,
                type: userType,
                password: password
            },
            type: "POST",
            success: function() {
                new Notification().success("Nutzer erstellt");
                that.listUsers(that.userContainer);
            },
            error: function() {
                new Notification().error("Nutzer konnte nicht erstellt werden");            
            }
        });
    },
    
    changePassword: function(username, password) {
        var that = this;
        $.ajax({
            url: "api/users/changePassword",
            data: {
                username: username,
                password: password
            },
            type: "POST",
            success: function() {
                new Notification().success("Passwort geändert");
            },
            error: function() {
                new Notification().error("Passwort konnte nicht geändert werden");            
            }
        });
    },
    
    editUser: function(username, firstname, lastname, mail, userType) {
        var that = this;
        $.ajax({
            url: "api/users/edit",
            data: {
                username: username,
                firstname: firstname,
                lastname: lastname,
                type: userType,
                mail: mail
            },
            type: "POST",
            success: function() {
                new Notification().success("Nutzer gespeichert");
                that.listUsers(that.userContainer);
            },
            error: function() {
                new Notification().error("Nutzer konnte nicht gespeichert werden");            
            }
        });
    },
    
    deleteUser: function(username) {
        var that = this;
        $.ajax({
            url: "api/users/delete",
            data: {
                username: username                
            },
            type: "POST",
            success: function() {
                new Notification().success("Nutzer gelöscht");
                that.listUsers(that.userContainer);
            },
            error: function() {
                new Notification().error("Nutzer konnte nicht gelöscht werden");            
            }
        });
    },

    getUserTypeSelection: function() {
        return `
            <div>
                <label>Nutzer-Typ</label>
                <select class="user_type browser-default" name="user_create_type">
                    <option value="user">Nutzer</option>
                    <option value="editor">Redakteur</option>
                    <option value="admin">Administrator</option>
                </select>
            </div>
        `
    },
    
    showCreateDialog: function() {
        var that = this;
    
        var dlg = new Dialog()
            .build()
            .addTitle("Nutzer erstellen")
            .addText("Erstellen Sie einen neuen Nutzer.")
            .addInput("Nutzername", "", "Nutzername", "create_user_username")
            .addInput("Passwort", "", "Passwort", "create_user_password", function() {}, "password")
            .addInput("Vorname", "", "Vorname", "create_user_firstname")
            .addInput("Nachname", "", "Nachname", "create_user_lastname")
            .addInput("E-Mail", "", "E-Mail", "create_user_mail")
            .addHtml(this.getUserTypeSelection())
            .addButton("Erstellen", "create_user_submit", function() {
                var username = dlg.getInput("create_user_username").val();
                var password = dlg.getInput("create_user_password").val();
                var firstname = dlg.getInput("create_user_firstname").val();
                var lastname = dlg.getInput("create_user_lastname").val();
                var mail = dlg.getInput("create_user_mail").val();
                let userType = dlg.getNode().find(".user_type").val();
                
                if (username.length == 0 || password.length < 6) {
                    new Notification().info("Bitte wählen Sie einen Nutzernamen sowie ein mindestens 6-stelliges Passwort");
                } else if (firstname + lastname == "") {
                    new Notification().info("Bitte wählen Sie einen Vor- und / oder Nachnamen");
                } else {                
                    dlg.close();
                    that.createUser(username, firstname, lastname, mail, userType, password);
                }
            })
            .addButton("Abbrechen", "create_user_cancel", function() {
                dlg.close();
            })
            .onEscape(function() {
                dlg.close();
            })
            .show();
    },
    
    showDeleteDialog: function(username) {
        var that = this;
        
        var dlg = new Dialog()
            .confirm(
                "Nutzer löschen", 
                `Wollen sie den Nutzer <i>${username}</i> wirklich löschen?`,
                "Löschen",
                "Abbrechen",
                function(conf) {
                    if (conf) {
                        that.deleteUser(username);
                    }
                }
            );
    },
    
    showEditDialog: function(username, firstnameOrig, lastnameOrig, mailOrig, typeOrig) {
        var that = this;
        
        var dlg = new Dialog()
            .build()
            .addTitle("Nutzer bearbeiten")
            .addText(`Bearbeiten Sie den Nutzer <i>${username}</i>.`)
            .addInput("Vorname", firstnameOrig, "Vorname", "edit_user_firstname")
            .addInput("Nachname", lastnameOrig, "Nachname", "edit_user_lastname")
            .addInput("E-Mail", mailOrig, "E-Mail", "edit_user_mail")
            .addHtml(this.getUserTypeSelection())
            .addButton("Speichern", "edit_user_submit", function() {
                var firstname = dlg.getInput("edit_user_firstname").val();
                var lastname = dlg.getInput("edit_user_lastname").val();
                var mail = dlg.getInput("edit_user_mail").val();
                let userType = dlg.getNode().find(".user_type").val();
                
                if (firstname + lastname == "") {
                    new Notification().info("Bitte wählen Sie einen Vor- und / oder Nachnamen");
                } else {                
                    dlg.close();
                    that.editUser(username, firstname, lastname, mail, userType);
                }
            })
            .addButton("Abbrechen", "edit_user_cancel", function() {
                dlg.close();
            })
            .onEscape(function() {
                dlg.close();
            })
            .show();

        dlg.getNode().find(".user_type").find(`option[value="${typeOrig}"]`).prop("selected", true);
    },
    
    showPasswordDialog: function(username) {
        var that = this;
        
        var dlg = new Dialog()
            .build()
            .addTitle("Passwort ändern")
            .addText(`Ändern Sie das Passwort des Nutzers <i>${username}</i>.`)
            .addInput("Passwort", "", "Passwort", "changepw_user_password", function() {}, "password")
            .addButton("Speichern", "changepw_user_submit", function() {                  
                var password = dlg.getInput("changepw_user_password").val();
                if (password.length < 6) {
                    new Notification().info("Bitte wählen Sie ein mindestens 6-stelliges Passwort");
                } else {                
                    dlg.close();
                    that.changePassword(username, password);
                }
            })
            .addButton("Abbrechen", "changepw_user_cancel", function() {
                dlg.close();
            })
            .onEscape(function() {
                dlg.close();
            })
            .show();
    },
    
    listUsers: function(container) {
        this.userContainer = container;
        
        var that = this;
        
        $.ajax({
            url: "api/users/list",
            type: "POST",
            success: function(resp) {
                $(that.userContainer).find(".loading").remove();
                that.displayUsers(resp.users);
            },
            error: function() {
                new Notification().error("Nutzer konnten nicht geladen werden");
                $(that.userContainer).find(".loading").remove();
            }
        });
    },
    
    displayUsers: function(users) {
        if (users.length == 0) {
            $(this.userContainer).html("<h5>Keine Nutzer vorhanden</h5>");
        } else {
            $(this.userContainer).html(`
                <table class="responsive-table">
                    <thead>
                        <tr>
                            <th>Nutzername</th>
                            <th>Name</th>
                            <th>Mail</th>
                            <th>Typ</th>
                            <th class="tdr">Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            `);
            for (var user of users) {
                $(this.userContainer).find("tbody").append(`
                    <tr>
                        <td>${user.username}</td>
                        <td>${user.firstname} ${user.lastname}</td>
                        <td>${user.mail}</td>
                        <td>${user.usertype}</td>
                        <td class="tdr">
                            <a href="admin/users/permissions/${user.username}">
                                <i class="material-icons pointer" data-user="${user.username}" title="Berechtigungen">lock</i>
                            </a>
                            <i class="material-icons pointer user_change_pw" data-user="${user.username}" title="Passwort ändern">password</i>
                            <i class="material-icons pointer user_edit" 
                                data-firstname="${user.firstname}" 
                                data-lastname="${user.lastname}" 
                                data-user="${user.username}"
                                data-mail="${user.mail}"
                                data-usertype="${user.usertype}"
                                title="Bearbeiten">edit</i>
                            <i class="material-icons pointer user_delete" data-user="${user.username}" title="Löschen">delete</i>
                        </td>
                    </tr>
                `);
            }
            
            M.AutoInit();
        }
    },

    showPermissionSelection: async function(container, username) {
        this.userContainer = $(container);

        var that = this;
        
        let response = await $.ajax({
            url: "api/users/permissions/get",
            type: "GET",
            data: {
                "username": username
            }            
        });

        this.displayPermissionManager(username, response.permissions);        
    },

    getPermissionStatusIcon(permissionStatus) {
        switch (permissionStatus) {
            case "inherited":
                return "bookmark";
            case "granted":
            case true:
                return "check";
            case "revoked":
            case false:
                return "block";
            default:
                return "help";
        }
    },

    updatePermissionStatus(permission) {
        let wrapper = this.userContainer.find(`.user-permission-controller[data-permission="${permission['name']}"]`);

        wrapper.find(".inherited-status").html(`
            <i class="material-icons">${this.getPermissionStatusIcon(permission["inherited_value"])}</i>
            `).toggleClass("granted", permission["inherited_value"]).toggleClass("revoked", !permission["inherited_value"]);

        wrapper.find(".resulting-status").html(`
            <i class="material-icons">${this.getPermissionStatusIcon(permission["resulting_value"])}</i>
        `).toggleClass("granted", permission["resulting_value"]).toggleClass("revoked", !permission["resulting_value"]);

        wrapper.find(".action-revoke").html(`
            <i class="material-icons">${this.getPermissionStatusIcon("revoked")}</i>
        `).toggleClass("selected", permission["explicit_value"] === "revoked");

        wrapper.find(".action-inherit").html(`
            <i class="material-icons">${this.getPermissionStatusIcon("inherited")}</i>
        `).toggleClass("selected", permission["explicit_value"] === "inherited");
        
        wrapper.find(".action-grant").html(`
            <i class="material-icons">${this.getPermissionStatusIcon("granted")}</i>
        `).toggleClass("selected", permission["explicit_value"] === "granted");
    },

    displayPermissionManager: function(username, permissionInfo) {
        let permissionGroups = [];        

        let container = this.userContainer.html(`
            <div class="user-permission-manager" data-user="${username}">

            </div>
        `);

        let that = this;
        container.on("click", ".permission-action", async function() {
            let parent = $(this).parents(".user-permission-controller");
            let button = parent.find("button.permission.action");
            button.prop("disabled", true);
            let permission = parent.data("permission");
            let action = false;
            if ($(this).hasClass("action-revoke")) {
                action = "revoke";
            } else if ($(this).hasClass("action-inherit")) {
                action = "inherit";
            } else if ($(this).hasClass("action-grant")) {
                action = "grant";
            }
            if (action === false) {
                new Notification().error("Keine Aktion spezifiziert");
                button.prop("disabled", false);
                return;
            }
            try {
                let response = await $.ajax({
                    url: "api/users/permissions/" + action,
                    type: "post",
                    data: {
                        "username": username,
                        "permission": permission 
                    }
                });
                //new Notification().info("Berechtigung gesetzt");
                let permissions = response.permissions;
                for (permission in permissions) {
                    that.updatePermissionStatus(permissions[permission]);
                }
            } catch (e) {
                new Notification().error("Aktion fehlgeschlagen");                
            }            
            button.prop("disabled", false);
        });

        for (let permissionName in permissionInfo) {
            let permission = permissionInfo[permissionName];
            console.log(permission);
            let groupName = `${permissionName}`.split("/")[0];
            let description = permission["description"];
            if (!permissionGroups.includes(groupName)) {
                permissionGroups.push(groupName);
                container.append(`
                    <div class="user-permission-group" data-group="${groupName}">
                        <div class="user-permission-group-name">${groupName}</div>
                    </div>
                `);
            }
            let permissionController = $(`
                <div class="user-permission-controller" data-permission="${permissionName}">
                    <div class="permission-info">
                        <span class="permission-name">${permissionName}</span>
                        <span class="permission-description">${description}</span>
                    </div>
                    <div class="permission-controls">
                        <div class="inherited-status" title="Vererbte Berechtigung">

                        </div>

                        <button class="action-revoke permission-action" title="Explizit verbieten">

                        </button>
                        <button class="action-inherit permission-action" title="Vererbt">

                        </button>
                        <button class="action-grant permission-action" title="Explizit erlauben">

                        </button>

                        <div class="resulting-status" title="Resultierende Berechtigung">

                        </div>
                    </div>
                </div>
            `);
            
            container.find(`.user-permission-group[data-group="${groupName}"]`).append(permissionController);
            this.updatePermissionStatus(permission);
        }
    }
}